import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FilterType } from 'src/app/_enums';
import { FilterDisplay, Product, ProductOffer } from 'src/app/_models';
import { HeaderService } from 'src/app/_services';

@Component({
	selector: 'product-filters',
	templateUrl: './product-filters.component.html',
	styleUrl: './product-filters.component.scss'
})
export class ProductFiltersComponent implements OnInit {
	@Input() businessIds: string[] | null = null
	@Input() hiveHandles: string[] | null = null
    @Input() page: number = 1
    @Input() perPage!: number
    @Input() filterType: FilterType = FilterType.Product
	@Output() recordOutput: EventEmitter<Product[] | ProductOffer[] | null> = new EventEmitter<Product[] | ProductOffer[] | null>

	
    filters: FilterDisplay[] = []
    
    constructor() { }

	ngOnInit(): void {
		this.initializeFilters()
	}

	private initializeFilters(): void {
		this.filters = [
            {
                name: 'Sort By',
                id: 'sort_by',
                urlText: 'sort_by',
                multiSelect: false,
                barFilter: false,
                filters: [
                    { name: "Price", id: 'price', value: 'price', isSelected: false },
                    // { name: 'Relevance', id: 'relevance', isSelected: false },
                    { name: 'Newest', id: 'newest', isSelected: false },
                    { name: 'Most Popular', id: 'most popular', isSelected: false },
                    { name: 'Alphabetical', id: 'alphabetical', value: 'name', isSelected: false },
                ]
            },
            {
                name: 'Hive',
                id: 'hive_names',
                urlText: 'hive',
                multiSelect: true,
                barFilter: true,
                hideOn: ['business_ids','hive_ids'],
                filters: []
            },
            {
                name: 'Category',
                id: 'category_names',
                urlText: 'category',
                multiSelect: true,
                barFilter: true,
                filters: []
            },
		]
        
        if(this.hiveHandles?.length) {
            let filters = []
            
            for(let h of this.hiveHandles) {
                filters.push({ name: '', id: '', value: h, isSelected: h != null })
            }
            
            this.filters.push({
                name: '',
                id: 'hive_ids',
                urlText: 'hive_ids',
                multiSelect: false,
                barFilter: false,
                hidden: true,
                filters: filters
            })
        }
        
        if(this.businessIds?.length && this.filterType) {
            let filters = []
            
            for(let b of this.businessIds) {
                filters.push({ name: '', id: '', value: b, isSelected: b != null })
            }
            
            this.filters.push(
                {
                    name: '',
                    id: 'business_ids',
                    urlText: 'business_ids',
                    multiSelect: false,
                    barFilter: false,
                    hidden: true,
                    filters: filters
                }
            )
        }
	}

    setProducts(products: Product[] | ProductOffer[] | null){
    	this.recordOutput.emit(products)
    }

}
