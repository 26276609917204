import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterBarService {
    isLoading: Subject<boolean> = new Subject<boolean>()
    theEnd: Subject<boolean> = new Subject<boolean>()
    reset: Subject<void> = new Subject<void>()
    
    constructor() { }
    
    
}
