import { Component, Input, Output, EventEmitter, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { FilterType } from 'src/app/_enums';
import { HubhiveEvent, FilterDisplay } from 'src/app/_models';

@Component({
	selector: 'event-filters',
	templateUrl: './event-filters.component.html',
	styleUrl: './event-filters.component.scss'
})
export class EventFiltersComponent implements OnInit {
	@Input() events: HubhiveEvent[] | null = null
	@Input() businessId: string | null = null
	@Input() hiveHandle: string | null = null
    @Input() page: number = 1
	@Output() recordOutput: EventEmitter<HubhiveEvent[]> = new EventEmitter<HubhiveEvent[]>

	filterType: FilterType = FilterType.HubhiveEvent
    filters: FilterDisplay[] = []
    
    constructor() {}

	ngOnInit(): void {
		this.initializeFilters()
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes["businessId"]) {
			this.initializeFilters()
		}
	}

	private initializeFilters(): void {
		this.filters = [
			{
				name: 'Sort By',
				id: 'sort_by', 
				urlText: 'sort_by',
				multiSelect: false,
				barFilter: true,
				filters: [
					{ name: 'Newest', id: 'newest', isSelected: false },
					{ name: 'Soonest', id: 'soonest', isSelected: false },
					{ name: 'Distance', id: 'distance', isSelected: false },
                    { name: 'Alphabetical', id: 'alphabetical', value: 'name', isSelected: false },
				]
			},
			{
                name: '',
                id: 'business_ids',
                urlText: 'business_ids',
                multiSelect: false,
                barFilter: false,
                hidden: true,
                filters: [
                    { name: '', id: '', value: this.businessId, isSelected: this.businessId != null }
                ]
            },
			{
				name: '',
				id: 'hive_ids',
				urlText: 'hive_ids',
				multiSelect: false,
				barFilter: false,
				hidden: true,
				filters: [
					{ name: '', id: '', value: this.hiveHandle, isSelected: this.hiveHandle != null }
				]
			},
			{
				name: 'Hives',
				id: 'hive_names',
				urlText: 'hive', 
				multiSelect: true,
				barFilter: true,
				hideOn: ['hive_ids'],
				filters: []
			},
		]
	}

    setEvents(events: HubhiveEvent[] | null){
		if (events) {
			this.recordOutput.emit(events)
		}
    }
}
