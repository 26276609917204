import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessFiltersComponent } from './components/business-filters/business-filters.component';
import { FilterBarComponent } from './components/filter-bar/filter-bar.component';
import { FilterModalComponent } from './components/filter-bar/filter-modal/filter-modal.component';
import { EventFiltersComponent } from './components/event-filters/event-filters.component';
import { ProductFiltersComponent } from './components/product-filters/product-filters.component';


@NgModule({
  declarations: [
    BusinessFiltersComponent,
    FilterBarComponent,
    FilterModalComponent,
    EventFiltersComponent,
    ProductFiltersComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BusinessFiltersComponent,
    FilterModalComponent,
    FilterBarComponent,
    EventFiltersComponent,
    ProductFiltersComponent,
    FilterModalComponent,
  ]
})
export class FiltersModule { }
