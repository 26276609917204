import { Component, Input, Output, TemplateRef, EventEmitter, OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core'
import { ModalService } from 'src/app/_services'
import { FilterType } from 'src/app/_enums'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { FilterAPIService } from 'src/app/_api-services/filter-api.service'
import { Business, Hive, HubhiveEvent, Product, ProductOffer, User } from 'src/app/_models'
import { HttpParams } from '@angular/common/http'
import { Subscription } from 'rxjs'
import { FilterBarService } from 'src/app/_services/filter-bar.service'
import { DetachedRouteHandleService } from 'src/app/_services/detached-route-handle.service'

@Component({
	selector: 'filter-bar',
	templateUrl: './filter-bar.component.html',
	styleUrl: './filter-bar.component.scss'
})
export class FilterBarComponent implements OnInit, OnDestroy, OnChanges {
	@Input() filterType!: FilterType
	@Input() filters: any = []
    @Input() page: number = 1
    @Input() perPage: number = 20
	@Output() recordOutput: EventEmitter<any[] | null> = new EventEmitter<any[] | null>
    
    private subscriptions: Subscription = new Subscription()
    private drhsSubs: Subscription = new Subscription()

	barFilter: any = []
	filterButtonSelected: boolean = false
	selectedFilter = '-1'
    currentURL: Params | null = null
    attached: boolean = true

	constructor(
		private filterAPIService: FilterAPIService,
        private filterBarService: FilterBarService,
		private modalService: ModalService,
		private router: Router,
		private route: ActivatedRoute,
        private drhs: DetachedRouteHandleService,
	) {}
    
    ngOnInit(): void {
		this.barFilter = this.filters?.filter((filter: any) => filter.barFilter || filter.secondaryBarFilter)

		if (this.filterType == null || this.filterType == undefined) {
			throw new Error('Input variable "filterType" must be set!')
		}
        
        this.drhsSubs.add(
            this.drhs.getting.subscribe( path => {
                path == this.drhs.getPath(this.route) ? this.onAttach() : this.onDetach()
            })
        )
        
        this.drhsSubs.add(
            this.drhs.setting.subscribe( path => {
                if(path == this.drhs.getPath(this.route)) {
                    this.onDetach()
                }
            })
        )
        
        this.setParamsSubscription()
	}
    
    ngOnDestroy(): void {
        this.drhsSubs.unsubscribe()
        this.subscriptions.unsubscribe()
    }

	ngOnChanges(changes: SimpleChanges): void {
		this.getRecords()
	}
    
    onAttach(): void {
        if(this.attached) {
            return
        }
        
        this.attached = true
    }
    
    onDetach(): void {
        if(!this.attached) {
            return
        }
        
        this.attached = false
    }
    
    setParamsSubscription(): void {
        this.subscriptions.add(
            this.route.queryParams.subscribe(url => {
                this.filterBarService.reset.next()    
                this.setUrl(url)
                this.getRecords()
            })
        )
    }
    
    setUrl(url: Params) {
        this.currentURL = url
    }

	getRecords(): void {
        if(!this.currentURL) {
            return
        }
        
        let params = this.getFiltersFromUrl(this.currentURL)
        params = params.set('page', this.page)
        params = params.set('per_page', this.perPage)
        
        this.filterBarService.isLoading.next(true)
        this.subscriptions.add(
            this.filterAPIService.getFilteredRecords(params, this.filterType).subscribe(res => {
                this.updateFilters(res["remaining_filters"], this.currentURL)
                this.filterButtonSelected = this.isFilterSelected(this.filters)
                this.doOutputEvent(res["filtered_records"])
                this.filterBarService.isLoading.next(false)
                
                if(!res['filtered_records']) {
                    this.filterBarService.theEnd.next(true)
                } else {
                    this.filterBarService.theEnd.next(false)
                }
            })    
        )
	}

	handleModalFilterClicked(selectedFilter: any){
		this.handleFilterClicked(selectedFilter['categoryId'], selectedFilter['filterId'])
	}

	handleFilterClicked(categoryId: string, filterId: string) {
		this.filters?.forEach((category: any) => {
			category.filters?.forEach((filter: any) => {
			if (category.id === categoryId && !category.multiSelect) {
				filter.isSelected = filter.id === filterId ? !filter.isSelected : false
				return
			}
			
			filter.isSelected = filter.id === filterId ? !filter.isSelected : filter.isSelected
			})
		})
		this.updateUrl()
	}

	updateUrl(){
		let params = new HttpParams()

		var selectedFilters = this.getSelectedFilters()

		for(const key in selectedFilters){
			if(selectedFilters[key] != ""){
				params = params.set(key, selectedFilters[key])
			}
		}

		let searchParam: any = this.getSearchParam()
		if (searchParam) {
			params = params.set('search', searchParam)
		}

		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: { params: params },
			queryParamsHandling: 'merge',
		});
	}

	getSearchParam(): any{
		const urlParams = this.route.snapshot.queryParams;
		const encodedParams = urlParams['params'];

		let searchParam: string | null = null

		if (encodedParams) {
			const decodedParams = new HttpParams({ fromString: encodedParams });
			searchParam = decodedParams.get('search');
		}

		return searchParam
	}

	getSelectedFilters(hidden: boolean = false){

		var selectedFilters: any = {}
        
		this.filters?.forEach((category: any) => {
			var categorySelection: any = []
			category.filters.forEach((filter: any) => {
                if (filter.isSelected && (category.hidden == hidden || !category.hidden)) {
                    categorySelection.push(filter.value)
                }
			})
            
			selectedFilters[category.urlText] = categorySelection.join(',')
		})
        
		return selectedFilters
	}

	categoryIsSelected(category: string): boolean{
		let selectedCategory = this.filters.find((cat: any) => cat.id == category)
		let isSelected = false
        
		if(selectedCategory){
			isSelected = selectedCategory?.filters?.some((filter: any) => filter.isSelected)
		}
        
		return isSelected
	}

	isFilterSelected(filters: any){
		let filterButtonSelected = false
        
		filters.forEach((filter: any) => {
			filter.filters.forEach((f:any) => {
                if(f.isSelected && f.name != ''){
                    filterButtonSelected = true
                }
			})
		})
        
		return filterButtonSelected
	}

	openFilterModal(content: TemplateRef<any>) {
		this.modalService.open('Sort and Filter', content)
	}

	updateFilters(remainingFilters: any, url: any){

		const params = new URLSearchParams(url['params'])

		this.filters?.forEach((category: any) => {
			const filterArray = params.get(category.urlText)?.split(',')

			const rFilter = remainingFilters?.find((filter: any) => filter.name == category.id)
			if(rFilter){

				let categoryFilters: any = []
				rFilter.filters?.forEach((f:any) => {

					let isSelected = false

					if (params.has(category.urlText) && filterArray?.includes(f.value)) {
					isSelected = true
					}
					let name = f.value + " " + (category.valueIcon ? category.valueIcon : "")
					categoryFilters.push({name: name, id: name+category.name, value: f.value, isSelected: isSelected})

				})
			
				if(!category.hidden){
					category.filters = categoryFilters
				}
				
				if(category.dependsOn && !this.categoryIsSelected(category.dependsOn)){
					category.filters = []
				}

				if (category.hideOn && Array.isArray(category.hideOn)) {
					category.hideOn.forEach((hide: any) => {
						if (hide && this.categoryIsSelected(hide)) {
							category.filters = [];
						}
					});
				}
			
			}
		})

		return this.filters
	}

	getFiltersFromUrl(url: any){
		let params = new HttpParams();
		let urlParams = this.parseUrlParams(url['params'])
		for(const key in urlParams){
			const category = this.filters?.find((cat: any) => key == cat.urlText)
			if(category) {
			    params = params.set(category.id, urlParams[key]);
			}
		}
		params = params.set('search_string', this.getSearchParam())

		var selectedFilters = this.getSelectedFilters(true)
		//add hidden filters
		for(const key in selectedFilters){
			if(selectedFilters[key] != ""){
				params = params.set(key, selectedFilters[key])
			}
		}

		return params
	}

	parseUrlParams(paramsString: string): any {
		const params: any = {}
		const decode = decodeURIComponent
		const entries = paramsString?.split('&').map(param => param.split('='))
		entries?.forEach(entry => {
			const [key, value] = entry
			params[decode(key)] = decode(value).split(',')
		});

		return params
	}

	doOutputEvent(records: any[]|null): void {
		let output: any[]|null = records

		switch(this.filterType) {
			case FilterType.Hive:
			case FilterType.UserHives:
				output = records as Hive[]
			break
			
			case FilterType.Business:
                output = records as Business[]
            break
            
            case FilterType.User:
                output = records as User[]
            break

			case FilterType.HubhiveEvent:
				output = records as HubhiveEvent[]
			break
            
            case FilterType.Product:
                output = records as Product[]
            break
            
            case FilterType.BusinessProducts:
                output = records as ProductOffer[]
            break
		}

		this.recordOutput.emit(output)
	}

}