<div class="filter-bar-container">
    <div class="filter-bar">
        <ul class="filter-tabs tabs reactive">
            <li class="btn-filter" [ngClass]="{'selected': filterButtonSelected}" (click)="openFilterModal(modalContent)">
                Filters
            </li>
            <li class="separator"></li>
            <ng-container class="tabs filter-tabs" *ngFor="let filterCategory of barFilter">
                <li *ngFor="let category of filterCategory.filters" class="btn-filter"
                    [ngClass]="{'selected': category.isSelected}" (click)="handleFilterClicked(filterCategory.id, category.id)">
                    {{ category.name }}
                </li>
            </ng-container>
        </ul>
    </div>
</div>


<ng-template #modalContent let-data="data">
    <filter-modal [filterCategories]="filters" [filterType]="filterType" (selectedFilter)="handleModalFilterClicked($event)"></filter-modal>
</ng-template>