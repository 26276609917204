import { Component, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core'
import { FilterType } from 'src/app/_enums'
import { Subscription, filter } from 'rxjs'
import { Business, SearchFilter, User} from 'src/app/_models'
@Component({
	selector: 'filter-modal',
	templateUrl: './filter-modal.component.html',
	styleUrl: './filter-modal.component.scss'
})

export class FilterModalComponent {
	@Input() filterCategories: any[] | undefined
	@Input() filterType!: FilterType
    @Input() defaultLayout: boolean = true
    @Input() scrollFilters: boolean = false
	@Output() selectedFilter: EventEmitter<SearchFilter> = new EventEmitter<SearchFilter>


	currentFilter: any
	urlFilter: any;

	constructor(
	) {}

	handleFilterSelected(categoryId: string, filterId: string): void {
		var filter: any = {}
		filter['categoryId'] = categoryId
		filter['filterId'] = filterId
		this.selectedFilter.emit(filter)
	}
}
