<form *ngIf="defaultLayout">
    <section *ngFor="let category of filterCategories" class="filter-container">
        <div *ngIf="category.filters && category.filters.length > 0 && category.name != ''">
            <h1>{{category.name}}</h1>
            <ul class="filter-tabs" [ngClass]="{'scroll': scrollFilters}">
                <li *ngFor="let filter of category.filters" class="btn-filter" [ngClass]="{'selected': filter.isSelected}" (click)="handleFilterSelected(category.id, filter.id)">
                    {{ filter.name }}
                </li>
            </ul>
        </div>
    </section>
</form>


<div *ngIf="!defaultLayout">
    <section *ngFor="let category of filterCategories" class="filter-container">
        <div *ngIf="category.filters.length > 0">
            <ul class="filter-tabs">
                <li *ngFor="let filter of category.filters" class="tab-filter" [ngClass]="{'selected': filter.isSelected}" (click)="handleFilterSelected(category.id, filter.id)">
                    {{ filter.name }}
                </li>
            </ul>
        </div>
    </section>
</div>
